<template>
  <div class=" border-gray-100 py-10 content-center text-center opacity-50">
    <!-- <img src="~/assets/wetix-logo-small-black.svg" alt="Powered by WeTix" class="h-18 self-center mx-auto mb-4" /> -->

    <p class="text-xs text-gray-500 mb-2">
      Event Ticketing Powered By
    </p>
    <img src="~/assets/wetix-logo-text-black.svg" alt="WeTix" class="h-7 self-center mx-auto mb-4">

    <p class="text-xs text-gray-400 mt-8">
      &copy; {{ new Date().getFullYear() }} WeTix Ltd. All rights reserved.
    </p>
    <!-- Build ID for debugging purposes -->
    <p class="text-xs text-gray-400 mt-2 opacity-30">
      {{ useRuntimeConfig().public.BUILD_ID }}
    </p>
  </div>
</template>
